$(document).ready(function() 
{
	
	if('ru' == document.documentElement.lang)
	{
		showLangSwitchPopupOnce();
	}
	
	$('.lang-switch-popup-close').click(
	function()
	{
		hideLangSwitchPopup();
	});
	
	function showLangSwitchPopupOnce()
	{
		if(!readCookie("langSwitchPopupShown"))
		{
			showLangSwitchPopup();
			setCookie("langSwitchPopupShown", true, 90);
		}
	}
	
	function showLangSwitchPopup()
	{
		if($('.lang-switch-popup-wrap').length)
		{
			$("body").css({"overflow":"hidden"});
			$('.lang-switch-popup-wrap').addClass('shown');
		}
	}
	
	function hideLangSwitchPopup()
	{
		$('.lang-switch-popup-wrap').removeClass('shown');
		$("body").css({"overflow":"visible"});
	}

	function setCookie(name, value, days)
	{
		var expires = "";
		if(days)
		{
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	function readCookie(name)
	{
		var nameEQ = name + "=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++)
			{
			var c = ca[i];
			while (c.charAt(0) == " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
});